import Lydia from "../img/partenaires/Logo_Lydia.png"
import Bnp from "../img/partenaires/BNP-Paribas-logo.png"
import RedBull from "../img/partenaires/redbull-logo-png-transparent.png"
import EVS from "../img/partenaires/Logo_EVS.png"


const NosPartenaires = () =>{
    return(
        <>
            <div className="divParts">
                <h2 className="titlesh2">Nos Partenaires</h2>
                <div className="divLogoParts">
                    <img src={Lydia} alt="Lydia" />
                    <img src={Bnp} alt="BNP" />
                    <img src={RedBull} alt="RB" />
                    <img src={EVS} alt="EVS" />
                </div>

            </div>
        </>
    )
}

export default NosPartenaires