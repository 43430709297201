import MyCarousel from "./carousel";
import pantheon from "../img/panthéon.png"
import WEI from "../img/wei1.jpg"

const Center = () => {
    return (
      <>
        <div className="Center">
          <div className="blocCenter">
            <div className="blocIncliné">
              {"<Bienvenue />"}
            </div>
            <div className="pantheonDiv">
            <img src={pantheon} alt="panthéon" className="pantheon"/>
            </div>
            <div className="infosCenterCarousel">
              <MyCarousel />
              <div className="infosCenter">
                <h2>MA2S, l’association étudiante de la licence MIASHS de Paris 1 Panthéon-Sorbonne, qui est là pour te représenter et animer votre vie étudiante !</h2>
                <p>Nous avons des élus étudiants, qui sont là pour vous défendre auprès des professeurs et de l’administration, tout au long de l’année, et de faire entendre vos revendications.</p>
                <p>En parallèle, nous organisons régulièrement des événements de cohésion pour nos étudiants, avec un week-end d’intégration, des sorties à thème, dans des musées, des pots, des soirées…Mais aussi des conférences, avec des intervenants spécialisés dans le domaine que vous étudiez !</p>
              </div>
            </div>
          </div>
        </div>
        <img src={WEI} alt="WEi" className="weiimage"/>
      </>
    );
  }
  
  export default Center;
  