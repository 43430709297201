import "./App.css"
//components
import Center from "./components/center";
import Footer from "./components/footer";
import Header from "./components/header";
import NosPartenaires from "./components/nospartenaire";
import Poles from "./components/poles";

function App() {
  return (
    <div className="App">
      <Header/>
      <Center/>
      <Poles />
      <NosPartenaires />
      <Footer />
    </div>
  );
}

export default App;
