import Comm from "../img/pôles/comm.png"
import Reprez from "../img/pôles/reprez.png"
import Event from "../img/pôles/event.png"
import Culture from "../img/pôles/culture.png"
const Poles = () =>{
    return(
        <div className="divPôles">
            <h2 >Nos différents Pôles !</h2>
            <div className="pôles">
                <div className="pôle">
                    <div>
                        <img src={Event} alt="Evenmentiel" />
                        <hr className="hr"/>
                        <h3>Pôle Evenementiel</h3>
                    </div>
                    <p>Le pôle événementiel de MA2S est le moteur de l'animation au sein de notre association. Notre équipe est constamment à l'œuvre pour organiser une variété d'événements tout au long l'année universitaire. De nos soirées festives tout au long de l'année, en passant par nos pots à thème tels que les fameux pots Halloween qui mettent l'ambiance, jusqu'à l'incontournable week-end d'intégration qui unit nos nouveaux membres dans une aventure inoubliable. Nous travaillons sans relâche pour s'assurer que MA2S reste synonyme de moments mémorables et de convivialité.</p>
                </div>
                <div className="pôle">
                    <div>
                        <img src={Reprez} alt="Représentation" />
                        <hr className="hr"/>
                        <h3>Pôle Représentation</h3>
                    </div>
                    <p>Le pôle représentation joue un rôle clé dans la défense des intérêts et des droits de nos étudiantes. Les bénévoles faisant partiede ce pôle agissent en tant que porte-parole et travaillant en étroite collaboration avec les responsables de la faculté afin d'améliorer les conditions d'études, de nos étudiants. Que ce soit dans le domaine de l'excellence académique, associatif, ou de l'égalité des chances. Les étudiants de ce pôle s'efforce de vous représenter durant les conseils d'UFR 27,  et d'agir dans les intêrets des étudiants de manière efficace.</p>
                   
                </div>
                <div className="pôle">
                    <div>
                        <img src={Comm} alt="Communication" />
                        <hr className="hr"/>
                        <h3>Pôle Communication</h3>
                    </div>
                    <p>La principale mission du pôle communication est de construire l’image de MA2S à travers ses réseaux sociaux et son site web. Il s’agit, de donner de la visibilité à l’association pour que les étudiants soient informés de nos actions et puissent en bénéficier. Mais aussi de concevoir des stratégies de communication innovantes, gèrent nos médias sociaux et veillent à ce que notre message parvienne à notre public cible. Ils sont également chargés de promouvoir nos événements, de maintenir notre présence en ligne.</p>
                </div>
                <div className="pôle">
                    <div>
                        <img src={Culture} alt="Evenmentiel" />
                        <hr className="hr"/>
                        <h3>Pôle Culture</h3>
                    </div>
                    <p>Le pôle culture de MA2S célèbre la richesse des mathématiques et de l'informatique sous toutes leurs formes. Nos membres organisent des expositions, des conférences sur des sujets passionnants, et des compétitions qui encouragent l'exploration de ces domaines fascinants. Ils promeuvent également la diversité des cultures en mathématiques et informatique en mettant en avant des initiatives qui mettent en valeur les différentes perspectives et talents des étudiants.</p>
                    
                </div>
            </div>
        </div>
    )
}

export default Poles