import Facebook from "../img/facebook.png"
import Instagram from "../img/instagram.png"
import Wave from "../img/wave.svg"
import logoFooter from "../img/logoFooter.png"




const Footer = () => {
  const openExternalLink = (link) => {
    window.open(link, '_blank');
  };
    return (
      <>
      <img src={Wave} alt="wave" className="zigzagCSS"/>
      <div className="Footer">
         <div className="logoEtMentionsLegales">
            <img src={logoFooter} alt="logo ma2s"/>
            <p>MA2S. Tous droits réservés.</p>
         </div>
         <div className="contacter">
            <button onClick={() => openExternalLink("https://forms.gle/qAe5deo3YfBp9xsC7")}>NOUS CONTACTER</button>
         </div>
         <div className="reseaux">
              <div className="instagramBlock">
                  <img  onClick={() => openExternalLink("https://www.instagram.com/ma2s.paris1/")} className="instafacebookLogo"src={Instagram} alt="Instagram" />
                  <p className="instafacebookText">Instagram</p>
              </div>
              <div >
                <img onClick={() => openExternalLink("https://www.facebook.com/MA2S.Sorbonne")}className="instafacebookLogo" src={Facebook} alt="Facebook" />
                <p className="instafacebookText">Facebook</p>
              </div>
         </div>

      </div>
      </>
      
    );
  }
  
  export default Footer;
  