import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import Bureau from "../img/bureau.png"
import Wei1 from "../img/wei1.png"
import distrib from "../img/distrib.png"

class MyCarousel extends Component {
  render() {
    return (
      <Carousel className="custom-carousel" autoPlay={true} interval={8000} showStatus={false}>
        <div>
          <img src={Bureau} alt="Ime 1" />
        </div>
        <div>
          <img src={Wei1} alt="Im 2"/>
        </div>
        <div>
          <img src={distrib} alt="Ime 3" />
        </div>
      </Carousel>
    );
  }
}

export default MyCarousel;