import Logo from "../img/logoMa2s.jpg"


const Header = () => {
  return (
    <div className="Header">
        <img src={Logo} alt="logo" className="Logo"/>
    </div>
  );
}

export default Header;
